import React from 'react'
import CommonButton from '../../../components/common/Button'
import { DownloadIcon } from '../../../assets/icons/Icons'
import { Grid } from '@mui/material'

const ReportDownload = () => {
    return (
        <>
            <Grid container spacing={1}>
                <Grid item><DownloadIcon /></Grid>
                <Grid item>Download</Grid>
            </Grid>
        </>
    )
}

export default ReportDownload