import * as React from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Bell, Setting, ProfileIcon, Notificationuser } from '../Constants'
import { Box, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useStyles } from '../../helper';
import { AccountCircleIcon, LogoutIcon } from '../../assets/icons/Icons';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '10px',
  border: '1px solid #FFF',
  background: '#F6F8F9',
  boxShadow: '2px 3px 10px 0px rgba(0, 0, 0, 0.15)',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  right: '0',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: '10px 15px',
    color: '#969BA0',
    fontSize: '12.937px',
  },
}));

const MyComponent = styled('div')({
  borderRadius: '10px',
  border: '1px solid #FFF',
  background: '#F6F8F9',
  boxShadow: '2px 3px 10px 0px rgba(0, 0, 0, 0.15)',
});

const Header = () => {
  const theme = useTheme();
  const classes = useStyles();
  const medium = useMediaQuery(theme.breakpoints.down('md'));
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [myprofile, setMyProfile] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = (event) => {
    setMyProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setMyProfile(null);
  };

  return (
    <>
      <Grid item>
        {
          small ? ''
            : <Search fullWidth
              sx={{ width: { sm: '300px', md: '250px', lg: '500px' } }}
            >
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                sx={{ width: { sm: '300px', md: '250px', lg: '500px' } }}
                fullWidth
                placeholder="Search here"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
        }
      </Grid>
      <Grid item sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '15px' }}>
        <MyComponent
          sx={{ padding: { xs: '8px' }, cursor: 'pointer' }}
          onClick={handleClick}
        >
          <img src={Bell} alt='Bell' width='20' />
        </MyComponent>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box paddingX={2} paddingY={1} onClick={handleClose}>
            <Grid container spacing={3} paddingBottom={1} alignItems='center' justifyContent='space-between'>
              <Grid item fontSize={18} fontWeight={600} sx={{ color: 'var(--primary)' }}>Notification</Grid>
              <Grid item fontSize={15} fontWeight={500} sx={{ color: 'var(--primary)' }}>Mark all as read</Grid>
            </Grid>
            {
              [1, 2, 3].map(() => {
                return (
                  <Box container spacing={3} className={classes.cardContainer} padding={2} marginTop={2} sx={{ cursor: 'pointer' }}>
                    <Grid container justifyContent='space-between' alignItems='center' flexWrap={'nowrap'}>
                      <Grid item>
                        <Grid container>
                          <Grid item xs={12}>New client added </Grid>
                          <Grid item xs={12}> on May 23, 2023 at 6:00 pm</Grid>
                        </Grid>
                      </Grid>
                      <Grid item><img src={Notificationuser} /></Grid>
                    </Grid>
                  </Box>
                );
              })
            }
          </Box>
        </Menu>
        <MyComponent onClick={() => { navigate('/admin/setting') }} sx={{ padding: { xs: '8px 9px 9px' }, cursor: 'pointer' }}
        >
          <img src={Setting} alt='Setting' width='18' />
        </MyComponent>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: { md: '20px', lg: '40px' }, cursor: 'pointer' }}
          onClick={handleClickProfile}>
          {
            medium ? ('') : <>
              <Divider orientation='vertical' flexItem />
              <div>Hello, Hana</div>
            </>
          }
          <img src={ProfileIcon} alt='ProfileIcon' width={40} />
        </Box>
        <Menu
          anchorEl={myprofile}
          open={Boolean(myprofile)}
          onClose={handleCloseProfile}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => { navigate('/admin/myprofile') }}>
            <div onClick={handleCloseProfile} style={{ display: 'flex' }}>
              <AccountCircleIcon color='primary' /> &nbsp; My Profile
            </div>
          </MenuItem>
          <MenuItem onClick={() => { navigate('/adminLogin') }}>
            <div onClick={handleCloseProfile} style={{ display: 'flex' }}>
              <LogoutIcon color='primary' /> &nbsp; Logout
            </div>
          </MenuItem>
        </Menu>
      </Grid>
    </>
  )
}

export default Header