import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Checkbox, Grid, Stack, Typography } from '@mui/material';
import { useStyles } from '../../../helper';
import AddRole from './AddRole';
import { AddIcon } from '../../../assets/icons/Icons';

const columns = [
    { id: 'name', label: 'Admin', flex: 1, minWidth: 100 },
    { id: 'read', label: 'Read', Width: 30, align: 'center' },
    { id: 'edit', label: 'Edit', Width: 30, align: 'center' },
    { id: 'delet', label: 'Delete', Width: 30, align: 'center' },

];

function createData(name, read, edit, delet) {
    return { name, read, edit, delet };
}

const rows = [
    createData('SM Channel Mgt', <Checkbox />, <Checkbox />, <Checkbox />),
    createData('Leads', <Checkbox />, <Checkbox />, <Checkbox />),
    createData('Client Mgt', <Checkbox />, <Checkbox />, <Checkbox />),
    createData('Keyword Planner', <Checkbox />, <Checkbox />, <Checkbox />),
    createData('Budget Calculator', <Checkbox />, <Checkbox />, <Checkbox />),
    createData('Reporting', <Checkbox />, <Checkbox />, <Checkbox />),
];

export default function ColumnGroupingTable() {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <Paper sx={{ width: '100%' }} className={classes.cardContainer}>
                <TableContainer >
                    <Table stickyHeader aria-label="sticky table" size="small" >
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" colSpan={1} style={{ fontWeight: '600' }}>
                                    Module Name
                                </TableCell>
                                <TableCell align="center" colSpan={3} style={{ fontWeight: '600' }}>
                                    Permission
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, fontWeight: '600' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}