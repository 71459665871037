import React, { useState } from 'react'
import PageHeading from '../../../components/common/PageHeading'
import { Grid, Stack, Typography } from '@mui/material';
import TableSort from '../../../components/common/Table/TableSort';
import FacebookAds from './facebook/FacebookAds';
import DateRange from '../../../components/common/Table/DateRange';


const AdsChannels = () => {
    const [dataFromChild, setDataFromChild] = useState('');

    const handleDataFromChild = (data) => {
        setDataFromChild(data);
    };

    return (
        <>
            <PageHeading heading='Social Media Channels Mgt' />

            <Stack alignItems="flex-end" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, flexDirection: { xs: 'column', sm: 'row' } }}>
                <Typography fontWeight='600' fontSize={{ xs: '15px', sm: '18px' }} color='var(--black)' width='100%'>{dataFromChild?.type?.name?.slice(0, -3) || 'Facebook'} Ads Performance</Typography>
                <Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
                    <Grid item>
                        <TableSort onData={handleDataFromChild} />
                    </Grid>
                    <Grid item>
                        <DateRange />
                    </Grid>
                </Grid>
            </Stack>
            {dataFromChild || <FacebookAds />}



            {/* <Grid className={classes.cardContainer}>
                <AdsChart />
            </Grid>

            <ThemeProvider theme={theme}>
                <Stack justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, marginTop: { xs: 2 }, flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 1 }, alignItems: { xs: 'start', sm: 'end' } }}>
                    <Typography fontWeight='600' fontSize={{ xs: '15px', sm: '18px' }} color='var(--black)' >Campaigns</Typography>
                    <TableSearch />
                </Stack>
                <DataGrid
                    disableColumnMenu
                    autoHeight
                    autoWidth
                    rows={adsrows}
                    columns={adscolumns}
                    pageSize={5}
                    className={classes.tableContainer}
                    
                    disableRowSelectionOnClick
                />
            </ThemeProvider> */}
        </>
    )
}

export default AdsChannels