import React from "react";
import { Route, Routes } from "react-router-dom";
import './App.css';
import Layout from './components/Layout/Layout';
import UserLayout from './components/userLayout/Layout';
import LogIn from './pages/admin/login/LogIn';
import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy";
import TermsOfService from "./pages/termsofservices/TermsOfService";
import { ThemeProvider } from "@mui/material";
import { theme } from "./helper";
import Login from "./pages/user/login/Login";
import ForgotPassword from "./pages/admin/login/ForgotPassword";
import SuccessMessage from "./pages/admin/login/SuccessMessage";
import UserForgotPassword from "./pages/user/login/UserForgotPassword";
import UserSuccessMessage from "./pages/user/login/UserSuccessMessage";

function App() {

  return (
    <ThemeProvider theme={theme}>
      <div className='background_Image'>
        <Routes>
          <Route path="/adminLogin" element={<LogIn page="login" />} />
          <Route path="/" element={<Login page="login" />} />
          {/* <Route path="/forgotPassword" element={<ForgotPassword/>} />
          <Route path="/success" element={<SuccessMessage/>} /> */}
          <Route path="/user-forgotPassword" element={<UserForgotPassword/>} />
          <Route path="/user-success" element={<UserSuccessMessage/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy page="privacy-policy" />} />
          <Route path="/terms-of-services" element={<TermsOfService page="terms-of-services" />} />
          {/* <Route
            path="*"
            element={
              <Layout />
            }
          ></Route> */}
          <Route
            path="*"
            element={
              <UserLayout />
            }
          ></Route>
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
