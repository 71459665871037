import React, { useState } from 'react'
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import {
  MainLogo,
  Vector1, Vector2, Vector7, Vector10,
   Ads, Dashboard, Leads, Reporting,
} from '../Constants'
import List from '@mui/material/List';
import { ListItemText } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const SidebarList = [
  {
    name: 'Dashboard',
    icon: Vector10,
    activeIcon: Dashboard,
    path: '/dashboard/'
  },
  {
    name: 'Leads',
    icon: Vector2,
    activeIcon: Leads,
    path: '/dashboard/leads'

  },
  {
    name: 'Ads Channels',
    icon: Vector1,
    activeIcon: Ads,
    path: '/dashboard/platform'
  },
  {
    name: 'Reports',
    icon: Vector7,
    activeIcon: Reporting,
    path: '/dashboard/report'
  },
]

const Sidebar = ({ handleDrawerToggle }) => {
  const [client, setClient] = React.useState('');

  const handleChange = event => {
    setClient(event.target.value);
  };
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <List sx={{
        padding: '24px 24px 15px 24px',
      }}>
        <img src={MainLogo} alt='main_logo' />
      </List>
      <List>
        {SidebarList.map((data, index) => (
          <ListItem onClick={() => navigate(data.path)} key={data} disablePadding sx={{ marginBottom: '8px' }}>
            <ListItemButton onClick={handleDrawerToggle} key={index} sx={{ backgroundColor: `${location.pathname == data.path ? '#e7f1f1' : ''}`, borderLeft: ` ${location.pathname == data.path ? '4px solid var(--primary) !important' : '4px solid transparent !important'}` }}>
              <ListItemIcon sx={{ minWidth: 30, width: '13px', height: '17px', }}>
                <img src={location.pathname == data.path ? data.activeIcon : data.icon} alt='' />
              </ListItemIcon>
              <ListItemText primary={data.name} sx={{ color: `${location.pathname == data.path ? 'var(--primary)' : ''}`, fontSize: '15px' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default Sidebar