import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Grid, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useStyles } from '../../../helper';

const lebel1 = [150, 300, 250, 400, 350];
const lebel2 = [100, 200, 150, 300, 250];
const lebel3 = [200, 250, 180, 280, 220];
const xLabels = ['2June', '3June', '4June', '5June', '6June'];

const App = () => {
  const [client, setClient] = React.useState('label1'); // Default to label1

  const handleChange = (event) => {
    setClient(event.target.value);
  };

  const classes = useStyles();

  const selectedData = client === 'label1' ? lebel1 : client === 'label2' ? lebel2 : lebel3;

  return (
    <>
      <Grid container padding={2} justifyContent='space-between' alignItems='center' position='absolute' top='0' zIndex='999'>
        <Grid item className={classes.chartHead}>Performance</Grid>
        <Grid item>
          <FormControl variant="outlined" sx={{ minWidth: 200 }}>
            <Select value={client} onChange={handleChange} label="Select Line">
              <MenuItem value="label1">Label 1</MenuItem>
              <MenuItem value="label2">Label 2</MenuItem>
              <MenuItem value="label3">Label 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <LineChart
        xAxis={[{ scaleType: 'point', data: xLabels }]}
        series={[
          { id: client, data: selectedData, color: 'var(--primary)' },
        ]}
        sx={{
          [`& .MuiAreaElement-series-${client}`]: {
            fill: 'linear-gradient(180deg, var(--primary) 0%, rgba(255, 255, 255, 0.00) 100%)',
          },
        }}
        height={350}
      />
    </>
  )
}

export default App;
