import { Grid, Stack } from '@mui/material'
import React from 'react'
import PageHeading from '../../../components/common/PageHeading'
import TableSort from '../../../components/common/Table/TableSort'
import { DataGrid } from '@mui/x-data-grid'
import { userleadscolumns, userleadsrows } from '../../../helper/indexUser'
import { useStyles } from '../../../helper'
import DateRange from '../../../components/common/Table/DateRange'
import TableDropDown from '../../../components/common/Table/TableDropDown'

const Leads = () => {
  const classes = useStyles();
  return (
    <>
      <Stack direction='row' alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, flexDirection: { xs: 'column', sm: 'row' }, gap: '8px' }}>
        <PageHeading heading='Leads' />
        <Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
          <Grid item>
            <TableDropDown/>
          </Grid>
          <Grid item>
            <DateRange />
          </Grid>
        </Grid>
      </Stack>

      <DataGrid
        disableColumnMenu
        autoHeight
        autoWidth
        rows={userleadsrows}
        columns={userleadscolumns}
        pageSize={5}
        className={classes.tableContainer}
        // 
        disableRowSelectionOnClick
      />
    </>
  )
}

export default Leads