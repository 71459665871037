import React from 'react'
import Stack from '@mui/material/Stack';
import { Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CommonInput from '../../../components/common/Input';
import CommonButton from '../../../components/common/Button';

const UserForgotPassword = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <Container sx={{
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: { xs: '10px', sm: '20px' },
      maxWidth: { xs: '370px', sm: '500px' },
    }}>
      <Typography align='center' sx={{ color: 'var(--heading)', fontSize: '30px', fontWeight: '800', }}>Forgot Password</Typography>
      <Typography align='center'>
        <Stack
          component="form"
          sx={{ width: { xs: '340px', sm: '450px' } }}
          spacing={3}
          noValidate
          autoComplete="off"
        >
          <CommonInput fullWidth placeholder="Email" />
        </Stack>
      </Typography>
      <Typography align='center' sx={{ paddingTop: { xs: '10px', sm: '18px' } }}>
        <CommonButton buttonName='Submit' onClick={() => { navigate('/user-success') }} padding='6px 40px' fontSize='20px'/>
      </Typography>
    </Container>
  )
}

export default UserForgotPassword