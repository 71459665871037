import React from 'react'
import { Button } from '@mui/material'

const CommonButton = (props) => {
	return (
		<Button variant="contained" size="large" sx={{
			borderRadius: `${props.borderRadius}`,
			padding: `${props.padding}` || { xs: '8px 30px', sm: '10px 60px' },
			textTransform: 'capitalize', background:  `${props.background}` || 'var(--primary)', minWidth: `${props.minWidth}`,
			fontSize: `${props.fontSize}` || { xs: '15px', sm: '20px' }, '&:hover': {
				backgroundColor: `${props.background}` || 'var(--primary)'
			},
		}}
			onClick={props.onClick}
		>
			{props.buttonName}
		</Button>
	)
}

export default CommonButton