import React from 'react'
import CommonButton from '../../../components/common/Button';
import { CloseIcon, LinkIcon, SendIcon } from '../../../assets/icons/Icons';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment } from '@mui/material';
import CommonInput from '../../../components/common/Input';


const ReportUrl = () => {
	const [openDelete, setOpenDelete] = React.useState(false);

	const handleOpenModal = () => {
		setOpenDelete(true);
	};
	const handleCloseModal = () => {
		setOpenDelete(false);
	};
	return (
		<>
            <Grid container spacing={1} onClick={handleOpenModal}>
                <Grid item><LinkIcon /></Grid>
                <Grid item>Link</Grid>
            </Grid>
			{/* <CommonButton buttonName={<LinkIcon />} fontSize='1rem' minWidth='40px' padding='7px 8px' onClick={handleOpenModal} /> */}

			<Dialog open={openDelete} onClose={handleCloseModal} sx={{ '& .MuiDialog-paper': { width: '500px' } }}>
				<DialogTitle>Share Report
					<IconButton
						aria-label="close"
						onClick={handleCloseModal}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: 'grey',
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers >
					<div className='p_relative'>
						<CommonInput fullWidth placeholder="https://www.google.com/" type='url'/>
						<InputAdornment position="end" className="eye_btn">
							<IconButton
								aria-label="toggle password visibility"
								edge="end"
							>
								<LinkIcon />
							</IconButton>
						</InputAdornment>
					</div>
				</DialogContent>
				{/* <DialogActions>
					<CommonButton onClick={handleCloseModal} buttonName="No" />
					<CommonButton onClick={handleCloseModal} buttonName="Yes" />
				</DialogActions> */}
			</Dialog>
		</>
	)
}

export default ReportUrl