import { TextField, ThemeProvider } from '@mui/material'
import React from 'react'
import { theme } from '../../../helper'

const TableSearch = () => {
    return (
        <ThemeProvider theme={theme}>
            <TextField hiddenLabel placeholder='Search' xs={{width:'100% !important'}}/>
        </ThemeProvider>
    )
}

export default TableSearch