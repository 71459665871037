import { Card, Grid, } from '@mui/material'
import React from 'react'
import { theme, useStyles } from '../../../helper'
import { Chart } from '../../../components/Constants';

const CounterData = [
    {
        para: 'Clicks',
        goal: '20k',
        number: '25.1k',
        incNum: '15',
    },
    {
        para: 'Impressions',
        goal: '100k',
        number: '102k',
        incNum: '20',
    },
    {
        para: 'Reach',
        goal: '5k',
        number: '4.1k',
        incNum: '15',
    },
    {
        para: 'Lead',
        goal: '10%',
        number: '6.25%',
        incNum: '15',
    },
]

const CounterCard = () => {
    const classes = useStyles();

    return (
        <Grid container spacing={{ xs: '10px', md: 3 }}>
            {
                CounterData.map((data, i) => {
                    return (
                        <Grid item theme={theme} xs={6} sm={6} md={3} key={i}>
                            <Card className={classes.cardContainer}>
                                <Grid container padding={{ xs: 2, md: 3 }} sx={{ background: 'var(--cardBG)' }}>
                                    <Grid item xs={8} className={classes.smallHead}>{data.para}</Grid>
                                    <Grid item xs={4} className={classes.counterImg}><img src={Chart} /></Grid>
                                    <Grid item xs={8}>
                                        <Grid container spacing={'3px'}>
                                            <Grid item xs={12} className={classes.counterNumber}>{data.number}</Grid>
                                            <Grid item xs={12} className={classes.goalHead}>Goal : {data.goal}</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4} className={classes.percentage}>+{data.incNum}%</Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    );
                })
            }
        </Grid>
    )
}

export default CounterCard