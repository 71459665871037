import React from 'react'
import PageHeading from '../../../components/common/PageHeading'
import CommonButton from '../../../components/common/Button';
import { userreportcolumns, userreportrows } from '../../../helper/indexUser';
import { DataGrid } from '@mui/x-data-grid';
import { useStyles } from '../../../helper';
import TableSearch from '../../../components/common/Table/TableSearch';
import TableSort from '../../../components/common/Table/TableSort';
import { Grid, Stack } from '@mui/material';
import DateRange from '../../../components/common/Table/DateRange';

const Report = () => {
  const classes = useStyles();

	return (
		<>
      <PageHeading heading='Reports' />
      <Stack direction='row' alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, marginTop: { xs: 2}, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px' }}>
        <TableSearch />
        <Grid container justifyContent='flex-end' alignItems='center'  gap={{xs:'5px',sm:'10px'}} width="inherit">
          {/* <Grid item>
            <TableSort />
          </Grid> */}
          <Grid item>
             <DateRange/>
          </Grid>
        </Grid>
      </Stack>
      <DataGrid
        disableColumnMenu
        autoHeight
        autoWidth
        rows={userreportrows}
        columns={userreportcolumns}
        pageSize={5}
        className={classes.tableContainer}
        // 
        disableRowSelectionOnClick
      />
		</>
	)
}

export default Report