import React from 'react'
import { Stack, Typography } from '@mui/material';

const PageHeading = (props) => {
	return (
		<>
			<Stack direction="row" alignItems="center" justifyContent="space-between" width='100%'>
				<div>
					<Typography className='page_heading' gutterBottom>
						{props.heading}
					</Typography>
					<Typography className='page_para' gutterBottom>
						{props.para} <span>{props.paraspan}</span>
					</Typography>
				</div>
				<div>
					{props.rightside}
				</div>

			</Stack>

		</>
	)
}

export default PageHeading