import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Sidebar from './Sidebar';
import Header from './Header';
import MenuIcon from '@mui/icons-material/Menu';
import { Grid, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Main from './Main';


const drawerWidth = 250;
const MyComponent = styled('div')({
    padding: '7px 6px',
    borderRadius: '10px',
    border: '1px solid #FFF',
    background: '#F6F8F9',
    boxShadow: '8px 6px 25px 0px rgba(0, 0, 0, 0.15)',
});

export default function ResponsiveDrawer() {
    console.log('111');
    const { window } = Window;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{
            display: 'flex', height: '100vh',
            width: '100vw'
        }}>
            <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    <Sidebar handleDrawerToggle={handleDrawerToggle} />
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        '& .MuiDrawer-paper': { backgroundColor: 'transparent' },
                    }}
                >
                    <Sidebar handleDrawerToggle={handleDrawerToggle} />
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'transparent', p: { xs: 2, sm: 3 }, height: '100vh', height: '100%', overflow: 'auto' }}
            >
                <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '25px' }}>
                    <MyComponent sx={{ display: { md: 'none' } }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ p: '0', m: '0' }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </MyComponent>
                    <Header />
                </Grid>

                <Box
                    component="main"
                    sx={{ flexGrow: 1, width: { md: `calc(100%)` } }}
                >
                    <Main />
                </Box>
            </Box>
        </Box>
    );
}
