import React from 'react'
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";


const CommonInput = (props) => {
    const outerTheme = useTheme();

    const customTheme = (outerTheme) =>
        createTheme({
            components: {
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '& .MuiInputBase-root': {
                                padding: '10px 15px',
                                border: "1.5px solid var(--secondary)",
                                borderRadius: '6px',
                                input: {
                                    padding:'0'
                                }
                            },
                            '& .Mui-focused': {
                                border: "1.5px solid var(--primary)",
                            },
                        },
                    }
                },
            }
        });

    return (
        <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField sx={props.sx} fullWidth={props.fullWidth} hiddenLabel placeholder={props.placeholder} type={props.type || 'text'} />
        </ThemeProvider>
    )
}

export default CommonInput