import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { MoreVertIcon } from '../../../assets/icons/Icons';
import { Menu } from '@mui/material';
import { useState } from 'react';
import DeleteModal from '../../../components/common/modal/DeleteModal';
import ReportUrl from './ReportUrl';
import ReportSend from './ReportSend';
import ReportDownload from './ReportDownload';

export default function ReportMoreBtn() {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <FormControl fullWidth>
            <MoreVertIcon onClick={handleClick} sx={{ cursor: 'pointer' }} />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem><ReportDownload/></MenuItem>
                <MenuItem><ReportUrl/></MenuItem>
                {/* <MenuItem><ReportSend/></MenuItem> */}
                <MenuItem><DeleteModal selectName='Delete' head='Report' para='this report' /></MenuItem>
            </Menu>
        </FormControl>
    );
}