import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Grid, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useStyles } from '../../../../helper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const lebel1 = [150, 300, 250, 400, 350, 180, 250, 180, 250, 200, 300, 250, 400];
const lebel2 = [400, 250, 300, 200, 250, 180, 250, 180, 350, 400, 250, 300, 180];
const xLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Nov', 'Dec'];

const YoutubeChart = () => {
    const [selectedLines, setSelectedLines] = React.useState(['label1', 'label2']);

    const handleChange = (event) => {
        setSelectedLines(event.target.value);
    };

    const legendStyles = {
        "--ChartsLegend-rootOffsetX": "120px",
        "--ChartsLegend-rootOffsetY": "-60px",
        "--ChartsLegend-itemMarkSize": "15px",
        "--ChartsLegend-itemWidth": "70px",
    };
    const classes = useStyles();
    return (
        <>
            <Grid
                container
                padding={2}
                justifyContent="flex-end"
                alignItems="center"
                position="absolute"
                top="0"
                zIndex="999"
            >
                <Grid item>
                    <FormControl variant="outlined" sx={{ Width: 100 }}>
                        <Select
                            multiple
                            value={selectedLines}
                            onChange={handleChange}
                            label="Select Line(s)"
                            endAdornment={<KeyboardArrowDownIcon />}

                        >
                            <MenuItem value="label1">Label 1</MenuItem>
                            <MenuItem value="label2">Label 2</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <LineChart
                xAxis={[{ scaleType: 'point', data: xLabels }]}
                series={selectedLines.map((line) => ({
                    id: line,
                    data: line === 'label1' ? lebel1 : lebel2,
                    label: line === 'label1' ? 'lebel1' : 'lebel2',
                    color: line === 'label1' ? '#e62117' : '#000000',
                }))}
                sx={{
                    [`& .MuiAreaElement-series-label1`]: {
                        fill: 'linear-gradient(180deg, #e62117 0%, rgba(255, 255, 255, 0.00) 100%)',
                    },
                    [`& .MuiAreaElement-series-label2`]: {
                        fill: 'linear-gradient(180deg, #000000 0%, rgba(255, 255, 255, 0.00) 100%)',
                    },
                    ...legendStyles,
                }}
                legend={{
                    directon: 'row',
                    position: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                }}
                height={300}
            />
        </>
    );
};

export default YoutubeChart;
