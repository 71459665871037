import React from 'react'
import Stack from '@mui/material/Stack';
import { Checkbox, Container, Grid, Typography } from '@mui/material';
import CommonButton from '../../../components/common/Button';
import CommonInput from '../../../components/common/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';

const LogIn = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const navigate = useNavigate();
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    return (
        <Container sx={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: { xs: '10px', sm: '20px' },
            maxWidth: { xs: '370px', sm: '500px' },
        }}>
            <Typography align='center' sx={{ color: 'var(--heading)', fontSize: '30px', fontWeight: '800', }}>Login</Typography>
            <Typography align='center' className='login_para'>Welcome back to <span>ADMS</span> </Typography>
            <Typography align='center'>
                <Stack
                    component="form"
                    sx={{ width: { xs: '340px', sm: '450px' } }}
                    spacing={3}
                    noValidate
                    autoComplete="off"
                >
                    <CommonInput fullWidth placeholder="Email" />
                    <div className='p_relative'>
                        <CommonInput fullWidth placeholder="Password" type={showPassword ? 'text' : 'password'} />
                        <InputAdornment position="end" className="eye_btn">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    </div>
                </Stack>
            </Typography>
            <Typography align='center' sx={{ width: '100%' }}>
                <Grid container sx={{ justifyContent: 'space-between' }}>
                    <Grid item sx={6}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item sx={6} className=''>
                                <Checkbox
                                    defaultChecked
                                    sx={{
                                        padding: '0',
                                        color: 'var(--primary)',
                                        '& .MuiSvgIcon-root': { fontSize: 28 },
                                        '&.Mui-checked': {
                                            color: 'var(--primary)',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item sx={6} className='login_para'>Remember me</Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={6} className='login_para'><a onClick={() => { navigate('/user-forgotPassword') }}>Forget Password?</a></Grid>
                </Grid>
            </Typography>
            <Typography align='center' sx={{ paddingTop: { xs: '10px', sm: '18px' } }}>
                <CommonButton buttonName='Login' onClick={() => { navigate('/dashboard/') }} padding='6px 60px' fontSize='20px' />
            </Typography>
        </Container>
    )
}

export default LogIn