import React from 'react'
import { Grid } from '@mui/material';
import CommonSelect from '../../../components/common/Select';
import { useStyles } from '../../../helper';
import { BarChart } from '@mui/x-charts/BarChart';

const data1 = [70, 35, 15, 40, 110, 70];
const data2 = [80, 40, 20, 50, 120, 80];
const xLabels = ['2June', '3June', '4June', '5June', '6June', '7June'];

const CostPerAdChart = () => {
  const [client, setClient] = React.useState('');

  const handleChange = event => {
    setClient(event.target.value);
  };
  const classes = useStyles();

  return (
    <>
      <Grid container padding={2} justifyContent='space-between' alignItems='center' position='absolute' top='0' zIndex='999'>
        <Grid item className={classes.chartHead}>Cost Per Ad</Grid>
      </Grid>
      <BarChart
        height={350}
        series={[
          { data: data1, label: 'Income', id: 'income', color: '#0B7770', },
          { data: data2, label: 'Outcome', id: 'outcome', color: '#00B9AC', },
        ]}
        legend={{
          directon: { xs: 'column', sm: 'row' },
          position: {
            vertical: "top",
            horizontal: "right"
          }
        }}
        sx={{
          "--ChartsLegend-rootOffsetX": { xs: '-140px', sm: '-180px' },
          "--ChartsLegend-rootOffsetY": { xs: '-30px', sm: '-60px' },
          '--ChartsLegend-itemMarkSize': '15px',
          '--ChartsLegend-itemWidth': '80px',
        }}
        xAxis={[{ data: xLabels, scaleType: 'band' }]}
      />
    </>
  )
}

export default CostPerAdChart