import { Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useStyles, gdnadscolumns, gdnadsrows } from '../../../../helper'
import { DataGrid } from '@mui/x-data-grid'
import TableSearch from '../../../../components/common/Table/TableSearch';
import GDNChart from './GDNChart';

const GDNAds = () => {
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.cardContainer} sx={{marginTop:'10px'}}>
        <GDNChart />
      </Grid>

      <Stack justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, marginTop: { xs: 2 }, flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 1 }, alignItems: { xs: 'start', sm: 'end' } }}>
        <Typography fontWeight='600' fontSize={{ xs: '15px', sm: '18px' }} color='var(--black)' >Campaigns</Typography>
        <TableSearch />
      </Stack>
      <DataGrid
        disableColumnMenu
        autoHeight
        autoWidth
        rows={gdnadsrows}
        columns={gdnadscolumns}
        pageSize={5}
        className={classes.tableContainer}
        disableRowSelectionOnClick
      />
    </>
  )
}

export default GDNAds
