import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../../pages/user/dashboard/Dashboard';
import MyProfile from '../../pages/user/myprofile/MyProfile';
import Leads from '../../pages/user/leads/Leads';
import AdsChannels from '../../pages/user/adschannel/AdsChannels';
import Setting from '../../pages/user/setting/Setting';
import Report from '../../pages/user/report/Report';

const Main = () => {
  return (
    <Routes>
      <Route exact path="/dashboard/" element={<Dashboard />} />
      <Route exact path="/dashboard/myprofile" element={<MyProfile />} />
      <Route exact path="/dashboard/leads" element={<Leads />} />
      <Route exact path="/dashboard/report" element={<Report />} />
      <Route exact path="/dashboard/platform" element={<AdsChannels />} />
      <Route exact path="/dashboard/setting" element={<Setting />} />
    </Routes>
  )
}

export default Main