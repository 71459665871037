import { FormControl, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import CommonButton from '../../../components/common/Button'
import CommonSelect from '../../../components/common/Select'
import CommonInput from '../../../components/common/Input'
import PageHeading from '../../../components/common/PageHeading'
import User from './User'

const inputItem = [
  {
    label: 'First Name',
    inputValue: 'Anna',
  },
  {
    label: 'Last Name',
    inputValue: 'Knight',
  },
  {
    label: 'Email address',
    inputValue: 'anna34@gmail.com',
  },
  {
    label: 'Change Password',
    inputValue: '******',
  },
]

const MyProfile = () => {
  const [admin, setAdmin] = React.useState('');

  const handleChange = event => {
    setAdmin(event.target.value);
  };

  return (
    <>

      <PageHeading heading='My Profile' para='Welcome to' paraspan='ADMS' />
      <Stack sx={{ padding: '0 !important', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: { xs: '15px', sm: '25px' }, marginTop: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography className='myprofile_head' fontWeight='600'>
            Personal Information
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Grid container spacing={{ xs: 1, sm: 4 }}>
            {inputItem.map((data, i) => {
              return (
                <Grid item xs={12} sm={5} md={5} key={i}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                      {data.label}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography className='myprofile_input' sx={{ width: '100%' }}>
                      <CommonInput fullWidth placeholder={data.label} value={data.inputValue} />
                    </Typography>
                  </Stack>
                </Grid>
              )
            })}
            <Grid item xs={12} sm={10} md={10}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                  Company Name
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography className='myprofile_input' sx={{ width: '100%' }}>
                  <CommonInput fullWidth placeholder='Company Name' value='NKDV Ltd.' />
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
          <CommonButton buttonName='Save Profile Info' />
        </Stack>
      </Stack>
    </>
  )
}

export default MyProfile