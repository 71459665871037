import React from 'react'
import { Checkbox, ListItemText, MenuItem, Select, ThemeProvider, createTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const names = [
    'Spam',
    'Converted',
    'No Response',
];

const QualitySelect = (props) => {
    const theme = createTheme({
        components: {
            // Name of the component
            MuiSelect: {
                styleOverrides: {
                    icon: {
                        color: 'var(--primary)',
                        zIndex: '-1'
                    },
                    outlined: {
                        padding: '0 !important',
                        border: `1px solid ${props.border || 'var(--white)'}`,
                    },
                    root: {
                        borderRadius: '5px',
                        background: `${props.background || 'var(--white)'}`,
                        color: `${props.color || 'var(--secondary)'}`,
                        padding: '6px 12px',
                        boxShadow: '2px 3px 10px 0px rgba(0, 0, 0, 0.15)',
                        width: '130px',
                    },
                },
            },
            MuiList: {
                styleOverrides: {
                    root: {
                        padding: '0'
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        padding: '4px 8px 4px 0'
                    },
                },
            }
        },
    });

    const [role, setRole] = React.useState(['Spam']);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setRole(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <ThemeProvider theme={theme}>
            <Select
                value={role}
                onChange={handleChange}
                renderValue={(selected) => selected.join(', ')}
                displayEmpty
                endAdornment={<KeyboardArrowDownIcon />}
                multiple
                defaultValue='Spam'
            >
                {names.map((name) => (
                    <MenuItem key={name} value={name}>
                        <Checkbox checked={role.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                    </MenuItem>
                ))}
            </Select>
        </ThemeProvider>
    )
}

export default QualitySelect