import React from 'react'
import PageHeading from '../../../components/common/PageHeading'
import { Grid, Stack } from '@mui/material'
import { useStyles } from '../../../helper'
import CounterCard from '../../admin/dashboard/CounterCard'
import CostPerAdChart from '../../admin/dashboard/CostPerAdChart'
import PerformanceChart from '../../admin/dashboard/PerformanceChart'
import DateRange from '../../../components/common/Table/DateRange'

const DashBoard = () => {
  const classes = useStyles();

  return (
    <>
      <Stack direction='row' alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, flexDirection: { xs: 'column', sm: 'row' }, gap: '8px' }}>
        <PageHeading heading='Dashboard' para='Welcome back to' paraspan='ADMS' />
        <Grid container justifyContent='flex-end' alignItems='center'  gap={{xs:'5px',sm:'10px'}} width="inherit">
          <Grid item>
             <DateRange/>
          </Grid>
        </Grid>
      </Stack>
      <Grid container spacing={{ xs: '10px', sm: 3 }}>
        <Grid item xs={12}>
          <CounterCard />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: '10px', sm: 3 }}>
            <Grid item md={7} xs={12}>
              <div className={classes.chartContainer}>
                <CostPerAdChart />
              </div>
            </Grid>
            <Grid item md={5} xs={12}>
              <div className={classes.chartContainer}>
                <PerformanceChart />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default DashBoard