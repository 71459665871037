import QualitySelect from "../pages/user/leads/QualitySelect";
import ReportMoreBtn from "../pages/user/report/ReportMore";

export const userleadsrows = [
    {
        id: 1,
        channel: 'Facebook',
        name: 'Maria Jems.',
        email: 'Mariya657@ex.com',
        phone: '12535 25412',
        message: '*****',
        subDate: '17/04/22',
        subTime: '9:45 AM',
        // url: 'https://lead1',
        // ip: '192.0.1',
        quality: <QualitySelect />,
    },
];
export const userleadscolumns = [
    { field: 'channel', headerName: 'Channel', minWidth: 120, flex: 1 },
    { field: 'name', headerName: 'Name', minWidth: 130, flex: 1 },
    { field: 'email', headerName: 'Email', minWidth: 130, flex: 1 },
    { field: 'phone', headerName: 'Phone', minWidth: 130, flex: 1 },
    { field: 'message', headerName: 'Message', minWidth: 140, flex: 1 },
    { field: 'subDate', headerName: 'Submission Date', minWidth: 170, flex: 1 },
    { field: 'subTime', headerName: 'Submission Time', minWidth: 170, flex: 1 },
    // { field: 'url', headerName: 'URL',minWidth:100,flex:1 },
    // { field: 'ip', headerName: 'IP Address',minWidth:150,flex:1 },
    { field: 'quality', headerName: 'Quality', renderCell: () => <QualitySelect />, minWidth: 150, flex: 1 },
];

export const useradsrows = [
    {
        id: 1,
        channel: 'Facebook',
        sessions: '152',
        sessionsdur: '00:00:14',
        newsessions: '5.2%',
        impressions: '345',
        reach: '34%',
        spend: '9.09',
        click: '7',
        ctr: '$12',
    },
];
export const useradscolumns = [
    { field: 'channel', headerName: 'Channel', minWidth: 120, flex: 1 },
    { field: 'sessions', headerName: 'Sessions', minWidth: 120, flex: 1 },
    { field: 'sessionsdur', headerName: 'Session Duration', minWidth: 150, flex: 1 },
    { field: 'newsessions', headerName: 'New Session', minWidth: 140, flex: 1 },
    { field: 'impressions', headerName: 'Impressions', minWidth: 130, flex: 1 },
    { field: 'reach', headerName: 'Reach', minWidth: 100, flex: 1 },
    { field: 'spend', headerName: 'Spend', minWidth: 100, flex: 1 },
    { field: 'click', headerName: 'Click', minWidth: 100, flex: 1 },
    { field: 'ctr', headerName: 'CTR', minWidth: 100, flex: 1 },
];

export const userreportrows = [
    {
        id: 1,
        name: 'xyz',
        date: '10/10/2023',
        client: 'abc',
        month: '1',
        // status: 'Generated ',
        action: <ReportMoreBtn />,
    },
];
export const userreportcolumns = [
    { field: 'name', headerName: 'Report Name', minWidth: 150, flex: 1 },
    { field: 'date', headerName: 'Date', minWidth: 120, flex: 1 },
    { field: 'client', headerName: 'Client', minWidth: 120, flex: 1 },
    { field: 'month', headerName: 'Month', minWidth: 150, flex: 1 },
    // { field: 'status', headerName: 'Status', minWidth: 150,flex:1 },
    { field: 'action', headerName: '', minWidth: 30, renderCell: () => <ReportMoreBtn />, flex: 0.2 },
];