import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import CommonButton from '../Button';
import { DeleteIcon } from '../../../assets/icons/Icons';

const DeleteModal = (props) => {
    const [openDelete, setOpenDelete] = React.useState(false);

    const handleOpenModal = () => {
        setOpenDelete(true);
    };
    const handleCloseModal = () => {
        setOpenDelete(false);
    };
    return (
        <>

            <div onClick={handleOpenModal}>
                {
                    props.selectName ?
                        <Grid container spacing={1} onClick={handleOpenModal}>
                            <Grid item><DeleteIcon /></Grid>
                            <Grid item>{props.selectName}</Grid>
                        </Grid>
                        :
                        <CommonButton buttonName={props.buttonName || <DeleteIcon />} fontSize='1rem' minWidth='40px' padding='7px 8px' />
                }
            </div>

            <Dialog open={openDelete} onClose={handleCloseModal} sx={{ '& .MuiDialog-paper': { width: '500px' } }}>
                <DialogTitle>Delete {props.head}</DialogTitle>
                <DialogContent dividers >
                    <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                        Are You Sure you want to delete {props.para} ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <CommonButton onClick={handleCloseModal} buttonName="No" />
                    <CommonButton onClick={handleCloseModal} buttonName="Yes" />
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DeleteModal