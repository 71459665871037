import React from 'react'
import { CloseIcon, EditIcon } from '../../../assets/icons/Icons'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import CommonButton from '../../../components/common/Button';
import ManageRole from './ManageRole';

const AddRole = (props) => {
    const [openAdd, setOpenAdd] = React.useState(false);
    const handleOpen = () => {
        setOpenAdd(true);
    };
    const handleClose = () => {
        setOpenAdd(false);
    };
    return (
        <>
            <div onClick={handleOpen}>
                <EditIcon sx={{ cursor: 'pointer' }} />
            </div>

            {/* <CommonButton o buttonName={} fontSize='1rem' minWidth='40px' padding='7px' /> */}

            <Dialog open={openAdd} onClose={handleClose} sx={{ '& .MuiDialog-paper': { width: '800px' } }}>
                <DialogTitle>Edit Permissions</DialogTitle>
                <DialogContent dividers >
                <Typography fontSize={20}>Role : Admin</Typography>
                    <ManageRole />
                </DialogContent>
                <DialogActions>
                    <CommonButton onClick={handleClose} buttonName="Cancel" />
                    <CommonButton onClick={handleClose} buttonName='Save' />
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AddRole